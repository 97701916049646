<template>
  <v-app>
    <DrawerComponent
      v-if="store.state.username !== 'loading...'"
      @logout="onLogout"
      @loader-expand="onLoaderExpand"
      @data-error="onDataError"
      @show-drawer="showDrawer"
    ></DrawerComponent>

    <v-main>
      <v-progress-circular
        v-if="store.state.username === 'loading' && loading"
        style="position: absolute; left: 50%; height: 100%"
        :width="7"
        :size="80"
        color="red"
        indeterminate
      ></v-progress-circular>
      <EmailVerifiedComponent v-if="store.state.emailVerified === 0"></EmailVerifiedComponent>
      <LoaderInformationComponent
        :_dialog="showLoaderInformation"
        :_confirmationDialog="showLoaderConfirmationDialog"
        :_confirmationInformation="loaderConfirmationInformation"
        @dialog-changed="onLoaderDialogChanged"
        @data-error="onDataError"
      ></LoaderInformationComponent>
      <v-dialog v-model="showError" persistent dark width="500">
        <v-alert id="data-error" type="error">
          failed to fetch data<br>please try again by refreshing the page
        </v-alert>
      </v-dialog>
      <router-view @data-error="onDataError">
      </router-view>
    </v-main>
  </v-app>
</template>

<script>
import AuthenticationService from "./services/AuthenticationService";
import BackendService from "./services/BackendService";
import DrawerComponent from "@/components/DrawerComponent.vue";
import LoaderInformationComponent from "@/components/LoaderInformationComponent.vue";
// import FooterComponent from "@/components/FooterComponent.vue"
import store from "@/store";
import EmailVerifiedComponent from "@/components/EmailVerifiedComponent.vue";
import axios from "axios";

export default {
  components: {
    EmailVerifiedComponent,
    DrawerComponent,
    LoaderInformationComponent,
    // FooterComponent
  },

  data: () => ({
    showLoaderInformation: false, // bool, general popout
    showLoaderConfirmationDialog: false, // bool, pops up when new hwid detected
    loaderConfirmationInformation: {
      deviceName: 'unknown'
    },

    mobileDrawer: false, // show mobile drawer version?
    showError: false, // shows 'failed to get data'
    fetchClientInfo: false, // if in dashboard views, constantly get loader status
    clientPollingInterval: null, // web socket query
    clientPollingSpeed: 1500,
    clientInjectedSuccessfully: false
  }),

  computed: {
    store() {
      return store
    },
    clientStatus() {
      return store.state.clientStatus;
    },
    loading() {
      return store.state.loading;
    }
  },

  methods: {
    axios,
    onDataError() {
      this.showError = true;
    },

    async onLogout() {
      await AuthenticationService.logout();
      this.$router.push("login");
    },

    onLoaderExpand() {
      this.showLoaderInformation = true;
    },

    onLoaderDialogChanged(val) {
      this.showLoaderInformation = val;
    },

    showDrawer(val) {
      this.fetchClientInfo = val;
    },

    setLoaderIssueReason(clientStatus) {
      if (clientStatus.status === 'ERROR') {
        if (clientStatus.error === 'CANNOT_GENERATE_HWID') {
          store.state.issueReason = 'failed to generate HWID'
        // } else if (clientStatus.error == 'initial setup required') {
        //   store.state.issueReason = 'initial setup required'
        } else if (clientStatus.error === 'HWID_RESET_REQUIRED') {
          store.state.issueReason = 'hwid reset required'
        } else if (clientStatus.error === 'OUTDATED_VERSION') {
          store.state.issueReason = 'outdated version'
        } else if (clientStatus.error === 'HWID_MISMATCH') {
          this.loaderConfirmationInformation.deviceName = clientStatus.deviceName;
          this.showLoaderConfirmationDialog = true;
          store.state.issueReason = 'hwid mismatch'
        } else {
          store.state.issueReason = 'injection failed'
          document.title = "Error! - sudocheats"
        }
      }
    },

    async setClientPollingInterval() {
      this.clientPollingInterval = setInterval(() => {
        this.getClientStatus();
      }, this.clientPollingSpeed);
    },

    async setTitle() {
      document.title = store.state.clientStatus.progress + "% - sudocheats"
    },

    async getClientStatus() {
      if (this.fetchClientInfo) {
        if (this.clientInjectedSuccessfully) {
          return;
        }
        const res = await BackendService.CLIENT_STATUS();

        if (res && res.status === 200) {
          if (res.data.status === 'DONE') {
            document.title = "Injected! - sudocheats"
            this.clientInjectedSuccessfully = true;
            setTimeout(() => {
              this.clientInjectedSuccessfully = false;
              document.title = "Inject - sudocheats"
            }, 15000);
          }

          store.state.clientStatus = res.data;
          this.setLoaderIssueReason(res.data)

          if (this.clientStatus.status === 'INJECTING' || this.clientStatus.status === 'PREPARING') {
            this.setTitle()
            if (this.clientPollingSpeed !== 200) {
              clearInterval(this.clientPollingInterval);
              this.clientPollingSpeed = 200;
              this.setClientPollingInterval();
            }
          } else {
            if (this.clientPollingSpeed !== 2000) {
              clearInterval(this.clientPollingInterval);
              this.clientPollingSpeed = 2000;
              this.setClientPollingInterval();
            }
          }
        } else {
          this.$emit('data-error')
        }
      }
    },

    async sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
  },

  async created() {
    // delay the request so we can be sure our JWT tokens are valid
    await this.sleep(1000);
    this.setClientPollingInterval();
  },

  mounted() {
    store.state.goToLoginPage = () => {
      this.$router.push("login");
    }

    this.getClientStatus();

    this.mobileDrawer = this.$vuetify.breakpoint.width <= 430;
  },

  watch: {
    $route: {
      immediate: true,
      handler(to) {
        document.title = to.meta.title || 'sudocheats';
      }
    },
  },

  destroyed() {
    clearInterval(this.clientPollingInterval);
  },
};
</script>

<style>
.v-main {
  background-color: #444;
}

#data-error {
  margin: 0px;
}

.drawer-divider {
  margin-left: 20px;
  margin-right: 20px;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #1f4368;
}
</style>
