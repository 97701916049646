<template>
  <div>
    <v-dialog v-model="showDialog" dark width="500">
      <v-alert v-if="error && error.length > 0" type="error">
        {{ error }}
      </v-alert>
      <v-alert v-else type="success">
        verification email successfully sent. please check your inbox.
      </v-alert>
    </v-dialog>

    <v-alert
      type="error"
      text
    >
      please verify your email. your access is currently restricted.<br/>
      get a verification email using the verify button. only once every 24 hours possible.
      <v-btn style="margin-left: 40px; top: -12px" @click="onClick" :disabled="btnDisable">
        <v-icon>
          mdi-email-check
        </v-icon>
        verify
      </v-btn>
    </v-alert>
  </div>
</template>


<script>
import BackendService from "@/services/BackendService";

export default {
  data: () => ({
    showDialog: false,
    error: "",
    btnDisable: false
  }),

  methods: {
    async onClick() {
      const res = await BackendService.WEB_AUTH_REQUEST_VERIFICATION_EMAIL();
      if (res && res.status === 200) {
        this.showDialog = true;
      }

      if (res.response.status === 400) {
        if (res.response.data.message === "ERROR_WAIT_24_HOURS") {
          this.error = "you have already requested an email in the last 24 hours"
          this.btnDisable = true
        } else {
          this.error = "unknown error, please try again in a few hours"
        }
        this.showDialog = true;
      }
    }
  }
}
</script>

<style scoped>
.v-dialog {
  margin: 0;
  padding: 0;
}

.v-alert {
  margin: 0;
  //padding: 0;
}
</style>