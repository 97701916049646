<template>
  <div>
    <v-dialog v-model="showLoaderGuide" dark width="600">
      <v-stepper v-model="loaderGuideStep">
        <v-stepper-header>
          <v-stepper-step :complete="loaderGuideStep > 1" step="1">
            Installation</v-stepper-step
          >
          <v-divider></v-divider>
          <v-stepper-step :complete="loaderGuideStep > 2" step="2">
            Injection
          </v-stepper-step>
        </v-stepper-header>
        <v-stepper-items>
          <v-stepper-content step="1">
            <v-expansion-panels>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  video tutorial - use for guidance
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <iframe
                      width="500"
                      height="315"
                      frameborder="0"
                      src="https://www.youtube.com/embed/rG2-PERyfl0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                  ></iframe>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            <v-card  style="margin-top: 20px; margin-bottom: 20px">
              <v-window vertical v-model="injectionStep">
                <v-window-item>
                  <v-card height="150px" elevation="0">
                    <p style="margin-top: 15px; font-size: 20px">
                      download installer onto your computer, but don't run it yet
                    </p>
                    <v-btn @click="downloadLoader" style="margin-top: 28px" outlined tile color="primary">
                      <v-icon>
                        mdi-download
                      </v-icon>
                      download installer
                    </v-btn>
                  </v-card>
                </v-window-item>
                <v-window-item>
                  <v-card height="150px" elevation="0">
                    <p style="margin-top: 15px; font-size: 20px">
                      run the following command in a terminal
                    </p>
                    <v-container fluid style="margin-left: 36px">
                      <v-row no-gutters align="center" justify="center">
                        <v-col>
                          <v-card width="450px" outlined tile>
                            <p style="margin: 15px; margin-bottom: 5px">
                              sudo chmod +x ./Downloads/sudocheats-installer && <br>
                              sudo ./Downloads/sudocheats-installer
                              <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    @click="copyCommandToClipboard"
                                    large
                                    v-on="on"
                                    v-bind="attrs"
                                    icon
                                    style="top: -2px; right: -15px"
                                  ><v-icon> mdi-clipboard-arrow-left </v-icon></v-btn>
                                </template>
                                <span>copy to clipboard</span>
                              </v-tooltip>
                            </p>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card>
                </v-window-item>
              </v-window>
              <v-card-actions class="justify-space-between">
                <v-btn
                  text
                  @click="injectionStep--"
                >
                  <v-icon>mdi-chevron-left</v-icon>
                </v-btn>
                <v-item-group
                  v-model="injectionStep"
                  class="text-center"
                  mandatory
                >
                  <v-item
                    v-for="n in 2"
                    :key="`btn-${n}`"
                    v-slot="{ active, toggle }"
                  >
                    <v-btn
                      :input-value="active"
                      icon
                      @click="toggle"
                    >
                      <v-icon>mdi-record</v-icon>
                    </v-btn>
                  </v-item>
                </v-item-group>
                <v-btn
                  text
                  @click="injectionStep++"
                >
                  <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>
            <!-- <v-expansion-panels v-if="!disableContinueStep1">
              <v-expansion-panel>
                <v-expansion-panel-header>
                  troubleshooting - run commands in box in terminal
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-card elevation="0">
                    <p>'GLIBCXX_3.4.32' not found</p>
                    <v-container fluid style="margin-top: -10px; margin-left: 15px">
                      <v-row no-gutters align="center" justify="center">
                        <v-col>
                          <v-card width="450px" outlined tile>
                            <p style="margin: 10px">
                              sudo add-apt-repository ppa:ubuntu-toolchain-r/test<br>
                              sudo apt-get update<br>
                              sudo apt-get install --only-upgrade libstdc++6<br>
                            </p>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card>
                  <v-card elevation="0">
                    <p>libssl.so.1.1: cannot open shared object file</p>
                    <v-container fluid style="margin-top: -10px; margin-left: 15px">
                      <v-row no-gutters align="center" justify="center">
                        <v-col>
                          <v-card width="450px" outlined tile>
                            <p style="margin: 10px">
                              wget http://archive.ubuntu.com/ubuntu/pool/main/o/openssl/libssl1.1_1.1.0g-2ubuntu4_amd64.deb<br>
                              sudo dpkg -i libssl1.1_1.1.0g-2ubuntu4_amd64.deb<br>
                            </p>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels> -->
            <v-btn style="margin-top: 20px" :disabled="disableContinueStep1" color="primary" @click="loaderGuideStep = 2">
              Continue
            </v-btn>
          </v-stepper-content>
          <v-stepper-content step="2">
            <v-expansion-panels>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  video tutorial - use for guidance
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <iframe
                      width="500"
                      height="315"
                      frameborder="0"
                      src="https://www.youtube.com/embed/-MVNVYhhheA"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                  ></iframe>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            <v-card style="margin-top: 20px; margin-bottom: 20px" height="218px" elevation="0">
              <v-container fluid>
                <p style="margin-top: 2px; font-size: 20px">
                  run the loader on your computer and <br> enter your connection key
                </p>
              </v-container>
              <v-container fluid style="margin-left: 36px">
                <v-row no-gutters align="center" justify="center">
                  <v-col>
                    <v-card width="450px" outlined tile>
                      <p style="margin: 15px">
                        <v-icon style="margin-left: 30px; top: -2px">mdi-shield-key</v-icon>
                        <span v-if="store.state.emailVerified === 0">
                          unavailable
                        </span>
                        <span v-else>
                          {{ connectionKey }}
                        </span>
                      </p>
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
            <v-btn color="primary" @click="loaderGuideStep = 1">
              Previous
            </v-btn>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-dialog>

    <v-dialog
      content-class="dialog"
      transition="slide-x-transition"
      v-model="dialog"
      hide-overlay
      dark
      width="350"
    >
      <v-card v-if="issueReason === 'outdated version'">
        <div style="height: 12px"></div>
        <p style="margin: 0; font-size: 18px">outdated version</p>
        <div style="height: 10px"></div>
        <v-divider></v-divider>
        <div style="height: 10px"></div>
        <p style="margin: 0">
          please follow the instructions for the loader installation by clicking
          the button below
        </p>
        <br />
        <v-btn
          @click="showLoaderGuideInstallation"
          color="green"
          style="margin-bottom: 10px"
          >INSTALLATION</v-btn
        >
      </v-card>
      <v-card v-else-if="issueReason === 'hwid reset required'">
        <div style="height: 12px"></div>
        <p style="margin: 0; font-size: 18px">hwid reset required</p>
        <div style="height: 10px"></div>
        <v-divider></v-divider>
        <div style="height: 10px"></div>
        <p style="margin: 0; padding-left: 20px; padding-right: 20px">
          a new computer is trying to connect to your account.<br /><br />your
          account is linked to one computer and in order to link your account to
          a new computer, you need to purchase a hwid reset in the purchase tab.
          alternatively, you can go back to using your old computer in order to
          avoid a hwid reset.
        </p>
        <br />
        <v-btn
          @click="goToPurchaseTab"
          color="green"
          style="margin-bottom: 10px"
          >purchase tab</v-btn
        >
      </v-card>
      <v-card v-else-if="issueReason === 'hwid mismatch'">
        <div style="height: 12px"></div>
        <p style="margin: 0; font-size: 18px">hwid reset</p>
        <div style="height: 10px"></div>
        <v-divider></v-divider>
        <div style="height: 10px"></div>
        <p style="margin: 0; padding-left: 20px; padding-right: 20px">
          a new computer is trying to connect to your account
        </p>
        <br />
        <v-btn
          @click="confirmationDialog = true"
          color="green"
          style="margin-bottom: 10px"
          >link new computer</v-btn
        >
      </v-card>
      <v-card v-else>
        <div style="height: 12px"></div>
        <p style="margin: 0; font-size: 18px">set up or run the loader</p>
        <div style="height: 10px"></div>
        <v-divider></v-divider>
        <div style="height: 10px"></div>
        <p style="margin: 0; padding-left: 20px; padding-right: 20px">
          it is required to set up the loader in order to use cheats. please use
          the buttons down below to set up the loader.<br /><br />start with
          installation
        </p>
        <br />
        <v-card-actions>
          <v-btn
            @click="showLoaderGuideInstallation"
            dark
            color="green"
            style="margin-bottom: 3px"
            :disabled="store.state.emailVerified === 0"
            >installation</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            @click="showLoaderGuideExecution"
            dark
            color="blue"
            style="margin-bottom: 3px"
            :disabled="store.state.emailVerified === 0"
            >Injection</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="confirmationDialog" dark persistent width="500">
      <v-card style="margin: 0">
        <div style="height: 12px"></div>
        <p style="margin: 0; font-size: 18px">hwid reset</p>
        <div style="height: 10px"></div>
        <v-divider></v-divider>
        <div style="height: 10px"></div>
        <p style="margin: 0; padding-left: 20px; padding-right: 20px">
          a new computer ({{ _confirmationInformation.deviceName }}) is trying to connect to your account. do you want to
          link your account to this computer?<br /><br />your hwid reset will be used.<br /><br />after linking your account you will be able
          to launch cheats only on that new computer.
        </p>
        <br />
        <v-card-actions>
          <v-btn
            @click="resetHwid"
            dark
            color="green"
            style="margin-bottom: 3px"
            >YES, link the computer</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            @click="confirmationDialog = false"
            dark
            color="red"
            style="margin-bottom: 3px"
            >NO</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import copy from "clipboard-copy";
import BackendService from "@/services/BackendService";
import store from "@/store";
import {inject} from "@vue/composition-api";
import FileSaver from 'file-saver'
export default {
  props: {
    _dialog: Boolean,
    _confirmationDialog: Boolean,
    _confirmationInformation: Object,
  },

  data() {
    return {
      dialog: this._dialog,
      confirmationDialog: this._confirmationDialog,

      showLoaderGuide: false,
      loaderGuideStep: 1,
      injectionStep: 0,
      disableContinueStep1: true
    };
  },

  methods: {
    inject,
    copyCommandToClipboard() {
      copy("sudo chmod +x ./Downloads/sudocheats-installer && sudo ./Downloads/sudocheats-installer");
    },

    showLoaderGuideInstallation() {
      this.loaderGuideStep = 1;
      this.showLoaderGuide = true;
    },

    showLoaderGuideExecution() {
      this.loaderGuideStep = 2;
      this.showLoaderGuide = true;
    },

    goToPurchaseTab() {
      this.$router.push("purchase");
      this.dialog = false;
    },

    async resetHwid() {
      const res = await BackendService.USER_CONFIRM_HWID_RESET();
      if (res && res.status === 200) {
        this.confirmationDialog = false;
      } else {
        this.$emit("data-error");
      }
    },

    async downloadLoader() {
      setTimeout(() => {
        this.injectionStep = 1;
      }, 1500);

      const res = await BackendService.CLIENT_DOWNLOAD();
      if (res && res.status === 200) {
        FileSaver.saveAs(res.data, "sudocheats-installer")
      } else {
        this.$emit("data-error");
      }
    }
  },

  computed: {
    store() {
      return store
    },
    issueReason() {
      return store.state.issueReason
    },
    connectionKey() {
      return store.state.connectionKey
    },
  },

  watch: {
    _dialog(val) {
      this.dialog = val;
    },

    injectionStep(val) {
      if (val === 1) {
        this.disableContinueStep1 = false
      }
    },

    dialog(val) {
      this.$emit("dialog-changed", val);
    },

    _confirmationDialog(val) {
      this.confirmationDialog = val;
    },
  },
};
</script>

<style scoped>
>>> .dialog {
  position: fixed;
  left: 290px;
  top: 50px;
}

.v-divider {
  margin-left: 20px;
  margin-right: 20px;
}
</style>

