import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from '@/plugins/vuetify'
import VueCookies from 'vue-cookies';
import Particles from "vue2-particles";
import Vuex from 'vuex'

import './interceptors/axios'
Vue.config.productionTip = false

Vue.use(Particles);
Vue.use(VueCookies);
Vue.use(Vuex);

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')