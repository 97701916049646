<template>
  <v-navigation-drawer permanent dark v-if="showDrawer" app :width="300">
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="text-h6"> sudocheats </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <p style="color: grey">
      <v-icon style="top: -1px">mdi-account</v-icon>
      {{ username }}
      <v-icon style="margin-left: 30px; top: -2px">mdi-shield-key</v-icon>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <span v-on="on" v-bind="attrs" v-if="store.state.emailVerified === 0">
            unavailable
          </span>
          <span v-on="on" v-bind="attrs" v-else ref="blink_me">
            {{ connectionKey }}
          </span>
        </template>
        <span>Connection Key</span>
      </v-tooltip>
    </p>
    <v-divider class="drawer-divider"></v-divider>
    <LoaderStatusComponent
      v-on="$listeners"
    ></LoaderStatusComponent>
    <v-divider class="drawer-divider"></v-divider>
    <br />
    <v-list dense nav>
      <v-list-item v-for="item in items" :key="item.title" :to="item.title">
        <v-list-item-icon>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item key="discord" :href="this.discordInviteLink" target="_blank">
        <v-list-item-icon>
          <v-icon>mdi-discord</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>discord</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <template v-slot:append>
      <div class="pa-2">
        <v-btn block @click="onLogout"> Logout </v-btn>
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
import LoaderStatusComponent from "@/components/LoaderStatusComponent.vue";
import store from "@/store";
import BackendService from "@/services/BackendService";

export default {
  components: {
    LoaderStatusComponent,
  },

  props: {
    _clientStatus: Object,
  },

  data() {
    return {
      items: [
        { title: "inject", icon: "mdi-rocket-launch" },
        { title: "subscriptions", icon: "mdi-credit-card-refresh" },
        { title: "purchase", icon: "mdi-currency-usd" },
        { title: "wallet", icon: "mdi-wallet" },
        { title: "referral", icon: "mdi-account-group" },
        { title: "settings", icon: "mdi-cog" },
      ],
      discordInviteLink: "http://discord.com"
    };
  },

  methods: {
    onLogout() {
      this.$emit("logout");
    },

    async sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
  },

  computed: {
    store() {
      return store
    },
    showDrawer() {
      var show = false;

      this.items.forEach((element) => {
        if (this.$route.name === element.title) {
          show = true;
        }
      });

      this.$emit("show-drawer", show);
      return show;
    },

    username() {
      return store.state.username
    },
    connectionKey() {
      return store.state.connectionKey
    },
    highlightKey() {
      return store.state.highlightConnectionKey
    }
  },

  watch: {
    highlightKey(val) {
      if (val) {
        this.$refs.blink_me.className = "blink_me"
      }
    }
  },

  async created() {
    if (this.highlightKey) {
      this.$refs.blink_me.className = "blink_me"
    }

    await this.sleep(1000);
    var res = await BackendService.DISCORD_INVITE_LINK();
    if (res && res.status === 200) {
      this.discordInviteLink = res.data;
    } else {
      this.$emit('data-error')
    }
  }
};
</script>

<style scoped>
.drawer-divider {
  margin-left: 20px;
  margin-right: 20px;
}

.blink_me {
  animation: blinker 0.8s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}
</style>
