import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);

const store = new Vuex.Store({
    state: {
        username: "loading...",
        connectionKey: "loading...",
        emailVerified: false,
        highlightConnectionKey: false,
        clientStatus: {
            status: 'UNKNOWN',
            productName: 'unknown',
            progress: -1
        },
        issueReason: "none",
        goToLoginPage: () => {
            // console.log("store.goToLoginPage not assigned")
        },
        loading: false
    },
    getters: {
        username: (state) => {
          return state.username
        },
        clientStatus: (state) => {
            return state.clientStatus
        },
    },
    mutations: {
        setUsername(state, username) {
            state.username = username;
        },
        setClientStatus(state, clientStatus) {
            state.clientStatus = clientStatus;
        }
    }
})
export default store;