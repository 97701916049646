import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/RedirectView.vue'),
    meta: {
      title: "sudocheats"
    }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/LoginView.vue'),
    meta: {
      title: "Login - sudocheats"
    }
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('../views/RegisterView.vue'),
    meta: {
      title: "Register - sudocheats"
    }
  },
  {
    path: '/reset',
    name: 'reset',
    component: () => import('../views/ResetPasswordView.vue'),
    meta: {
      title: "Reset Password - sudocheats"
    }
  },
  {
    path: '/status',
    name: 'status',
    component: () => import('../views/StatusView.vue'),
    meta: {
      title: "Cheat Status - sudocheats"
    }
  },
  {
    path: '/inject',
    name: 'inject',
    component: () => import('../views/InjectView.vue'),
    meta: {
      title: "Inject - sudocheats"
    }
  },
  {
    path: '/subscriptions',
    name: 'subscriptions',
    component: () => import('../views/SubscriptionsView.vue'),
    meta: {
      title: "Subscriptions - sudocheats"
    }
  },
  {
    path: '/purchase',
    name: 'purchase',
    component: () => import('../views/PurchaseView.vue'),
    meta: {
      title: "Purchase - sudocheats"
    }
  },
  {
    path: '/referral',
    name: 'referral',
    component: () => import('../views/ReferralView.vue'),
    meta: {
      title: "Referral - sudocheats"
    }
  },
  {
    path: '/settings',
    name: 'settings',
    component: () => import('../views/SettingsView.vue'),
    meta: {
      title: "Settings - sudocheats"
    }
  },
  {
    path: '/wallet',
    name: 'wallet',
    component: () => import('../views/WalletView.vue'),
    meta: {
      title: "Wallet - sudocheats"
    }
  },
]

const router = new VueRouter({
  routes
})

router.afterEach((to) => {
  if (to.name === 'inject' ||
    to.name === 'subscriptions' ||
    to.name === 'purchase' ||
    to.name === 'referral' ||
    to.name === 'settings' ||
    to.name === 'wallet') {
    store.state.loading = true
  }
})
export default router
