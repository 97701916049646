import axios from "axios";
import AuthenticationService from "@/services/AuthenticationService";
import store from "@/store";

let refresh = false;

if (process.env.NODE_ENV === 'development') {
    axios.defaults.headers.common['Access-Control-Allow-Origin'] = 'http://localhost:8080/';
} else {
    axios.defaults.headers.common['Access-Control-Allow-Origin'] = 'https://sudocheats.com/';
}

axios.interceptors.response.use(resp => resp, async error => {
    if (refresh) {
        throw error;
    }

    // token validation error
    store.state.loading = false
    if (error.response.status === 403) {
        // tell other requests that we are currently refreshing the token already
        refresh = true;
        // console.log("getting new token...")

        // get new access token by refresh token in cookie
        const res = await AuthenticationService.getAccessToken();

        // got access token
        if (res && res.status === 200) {
            // console.log("setting new token")
            // set access token
            axios.defaults.headers.common['Authorization'] = `Bearer ${res.data.accessToken}`
            delete error.config.headers['Authorization'];

            // tell other requests that the refresh is done
            refresh = false;

            // re-send original request
            return axios(error.config);
        } else {
            // console.log("failed to get new token, user needs to login")
            store.state.goToLoginPage();

            // failed to get new access token, refresh token invalid?
            // make the user log back in
            refresh = false;
            throw undefined
        }
    }

    throw error;
});