import axios from 'axios';
import store from "@/store";

var baseUrl = '';
if (process.env.NODE_ENV === 'development') {
    baseUrl = 'http://localhost:2001/api/v1/web';
} else {
    baseUrl = "https://sudocheats.com/api/v1/web";
}

const urlWebAuth = baseUrl + '/auth/';
const urlWebDashboard = baseUrl + '/dashboard/';

const urlClient = baseUrl + '/client/';
const urlLicense = baseUrl + "/license/";
const urlUser = baseUrl + "/user/";
const urlProduct = baseUrl + '/product/';
const urlInviteCode = baseUrl + '/inviteCode/';
const urlWallet = baseUrl + '/wallet/';
const urlDiscord = baseUrl + '/discordLink/';

class BackendService {
    // ------------------ WEB ENDPOINTS ------------------
    // /web/auth
    static async WEB_AUTH_REGISTER(recaptchaToken, username, password,
        email, inviteCodeUsed) {
            const params = { username: username, password: password,
            email: email, inviteCodeUsed: inviteCodeUsed };
            const res = await axios.post(urlWebAuth + 'register', params, {
                headers: { 'Authorization': recaptchaToken },
            }).catch((err) => {
                return err
            });

            if (res && res.status === 200) {
                axios.defaults.headers.common['Authorization'] = `Bearer ${res.data.accessToken}`
                store.state.username = res.data.username
                store.state.connectionKey = res.data.connectionKey
                store.state.emailVerified = res.data.emailVerified
            }

            return res;
    }

    static async WEB_AUTH_REQUEST_PASSWORD_RESET(recaptchaToken, email) {
        const res = await axios.post(urlWebAuth + 'requestPasswordReset',
            { email }, {
            headers: { 'Authorization': recaptchaToken },
        }).catch((err) => {
            return err
        });

        return res;
    }

    static async WEB_AUTH_RESET_PASSWORD(token, password) {
        const params = { token: token, password: password }
        const res = await axios.post(urlWebAuth + 'resetPassword', params).catch((err) => {
            return err
        });

        return res;
    }

    static async WEB_AUTH_REQUEST_VERIFICATION_EMAIL() {
        const res = await axios.post(urlWebAuth + 'requestVerificationEmail', {}).catch((err) => {
            return err
        });

        return res;
    }

    static async WEB_AUTH_VERIFY_EMAIL(token) {
        const res = await axios.post(urlWebAuth + 'verifyEmail', { token }).catch((err) => {
            return err
        });

        return res;
    }

    // /web/dashboard
    static async WEB_DASHBOARD(endpoint) {
        var url = urlWebDashboard;
        switch (endpoint) {
            case 'inject':
                url += 'inject';
                break;

            case 'subscriptions':
                url += 'subscriptions';
                break;

            case 'purchase':
                url += 'purchase';
                break;

            case 'referral':
                url += 'referral';
                break;

            case 'settings':
                url += 'settings';
                break;

            case 'wallet':
                url += 'wallet';
                break;

            default:
                return false;
        }

        const res = await axios.get(url).catch((err) => {
            return err;
        });

        return res;
    }

    // ------------------ CLIENT ENDPOINTS ------------------
    static async CLIENT_INJECT(productName) {
        const res = await axios.post(urlClient + 'inject?productName=' + productName,
            {}, {
            withCredentials: true
        }).catch((err) => {
            return err;
        });

        return res;
    }

    static async CLIENT_STATUS() {
        const res = await axios.get(urlClient + 'status', {}).catch((err) => {
            return err;
        });

        return res;
    }

    static async CLIENT_DOWNLOAD() {
        const res = await axios.get(urlClient + 'download', {responseType: 'blob'}).catch((err) => {
            return err;
        });

        return res;
    }

    // other stuff

    static async USER_CONFIRM_HWID_RESET() {
        const res = await axios.post(urlUser + 'confirmHwidReset').catch((err) => {
            return err;
        });

        return res;
    }

    static async USER_UPDATE_ACCOUNT(obj) {
        const res = await axios.post(urlUser + 'updateAccount', obj).catch(async (err) => {
            return err;
        });

        return res;
    }

    static async PRODUCT_STATUS() {
        const res = await axios.get(urlProduct + 'status').catch((err) => {
            return err
        });

        return res;
    }

    static async DISCORD_INVITE_LINK() {
        const res = await axios.get(urlDiscord + 'latest').catch((err) => {
            return err
        });

        return res;
    }

    static async LICENSE_REDEEM(key) {
        const params = { key: key }
        const res = await axios.get(urlLicense + 'redeem', { params: params }).catch((err) => {
            return err;
        });

        return res;
    }

    static async INVITE_CODE_VERIFY(recaptchaToken, inviteCode) {
        const res = await axios.get(urlInviteCode + 'verify', {
            headers: { 'Authorization': recaptchaToken },
            params: { 'inviteCode': inviteCode }
        }).catch((err) => {
            return err;
        });

        return res;
    }

    static async PRODUCT_PURCHASE(obj) {
        const params = { productName: obj.productName, days: obj.time }
        const res = await axios.post(urlProduct + 'purchase', params).catch((err) => {
            return err;
        });

        return res;
    }

    static async PRODUCT_PURCHASE_HWID_RESET() {
        const res = await axios.post(urlProduct + 'purchaseHwidReset').catch((err) => {
            return err;
        });

        return res;
    }

    static async UTIL_WALLET_SUBMIT_GITFTCARD(platform, key, amount) {
        const params = { platform: platform, key: key, amount: amount }
        const res = await axios.post(urlWallet + 'submitGiftcard', params).catch((err) => {
            return err;
        });

        return res;
    }
}

export default BackendService;
