import axios from 'axios';
import store from "@/store";

var baseUrl = '';
if (process.env.NODE_ENV === 'development') {
    baseUrl = 'http://localhost:2001/api/v1/web/auth/';
} else {
    baseUrl = "https://sudocheats.com/api/v1/web/auth/";
}

const urlLogin = baseUrl + 'login';
const urlLogout = baseUrl + 'logout';
const urlRefreshToken = baseUrl + "token";

class AuthenticationService {
    static async login(username, password, recaptchaToken) {
        const params = { username: username, password: password };
        const res = await axios.post(urlLogin, params, {
            withCredentials: true,
            headers: { 'Authorization': recaptchaToken }
        }).catch((err) => {
            return err
        });

        if (res && res.status === 200) {
            axios.defaults.headers.common['Authorization'] = `Bearer ${res.data.accessToken}`
            store.state.username = res.data.username
            store.state.connectionKey = res.data.connectionKey
            store.state.emailVerified = res.data.emailVerified
        }

        return res
    }

    static async getAccessToken() {
        const res = await axios.post(urlRefreshToken, {
            withCredentials: true
        }).catch((err) => {
            return err;
        });

        if (res && res.status === 200) {
            store.state.username = res.data.username
            store.state.connectionKey = res.data.connectionKey
            store.state.emailVerified = res.data.emailVerified
        }

        return res;
    }
 
    static async logout() {
        const res = await axios.post(urlLogout, {}, {
            withCredentials: true,
        }).catch((err) => {
            axios.defaults.headers.common['Authorization'] = ''
            return err;
        });
        axios.defaults.headers.common['Authorization'] = ''
        return res;
    }
}

export default AuthenticationService;