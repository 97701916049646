<template>
  <div>
    <v-alert
      class="drawer-alert"
      type="info"
      dense
      border="left"
      v-if="clientStatus.status === 'READY'"
    >
      ready for launch
    </v-alert>
    <v-alert
      class="drawer-alert"
      type="error"
      dense
      border="left"
      v-else-if="clientStatus.status === 'ERROR'"
    >
      {{ issueReason }}
      <v-btn @click="onExpand" icon style="height: 0; width: 0; left: 25px; top: -1px">
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
    </v-alert>
    <v-alert
        class="drawer-alert"
        type="error"
        dense
        border="left"
        v-else-if="clientStatus.status === 'DISCONNECTED'"
    >
      loader not running
      <v-btn @click="onExpand" icon style="height: 0; width: 0; left: 25px; top: -1px">
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
    </v-alert>
    <v-alert
      class="drawer-alert"
      type="info"
      dense
      border="left"
      v-else-if="clientStatus.status === 'INJECTING'"
    >
      launching {{ clientStatus.productName }} ({{ clientStatus.progress }}%)
    </v-alert>
    <v-alert
        class="drawer-alert"
        type="info"
        dense
        border="left"
        v-else-if="clientStatus.status === 'PREPARING'"
    >
      preparing {{ clientStatus.productName }} ({{ clientStatus.progress }}%)
    </v-alert>
    <v-alert
      class="drawer-alert"
      type="success"
      dense
      border="left"
      v-else-if="clientStatus.status === 'DONE'"
    >
      injected {{ clientStatus.productName }}
    </v-alert>
    <v-alert
      class="drawer-alert"
      type="warning"
      dense
      border="left"
      v-else-if="clientStatus.status === 'UNKNOWN'"
    >
      loading...
    </v-alert>
  </div>
</template>

<script>
import store from "@/store";

export default {
  data() {
    return {};
  },

  methods: {
    onExpand() {
      this.$emit('loader-expand');
    }
  },

  computed: {
    clientStatus() {
      return store.state.clientStatus;
    },
    issueReason() {
      return store.state.issueReason
    }
  }
};
</script>

<style scoped>
.drawer-alert {
  margin: 20px;
}
</style>
